<template>
  <v-app>
  <div class="login-frame">
    <v-overlay :value="$store.state.overlay.show" :absolute="false">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>

    <div class="login-form">
      <router-link to="/" class="links">
        <v-img max-height="64" max-width="172" class="logo"
               v-bind:src="require('@/assets/images/Leita_blue.png')">
        </v-img>
      </router-link>
      <validation-observer
          ref="observer"
          v-slot="{ invalid }"
          @input="changeColorByObserver()"
      >
        <v-form
            ref="form"
            @submit.prevent="restore($data)"
        >


          <label for="email" style="padding-bottom: 12px;">E-mail</label>
          <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
          >
            <v-text-field
                v-model="email"
                :error-messages="errors"
                class="v-text-field-custom"
                tile
                solo
                placeholder="myemail@gmail.com"
            ></v-text-field>
          </validation-provider>


          <v-col style="padding: 0px; padding-bottom: 20px;">
            <v-btn
                tile
                :color="submitDisableButtonColor"
                style="width: 100%;"
                x-large
                :disabled="invalid"
                type="submit"
            >
              <span class="login-button-text"> Restore Password</span>


            </v-btn>
          </v-col>


        </v-form>
      </validation-observer>
      <v-container>
        <v-row style="padding-bottom: 8px;">
          <v-col cols="10" style="padding-left: 0px;"><span class="links" style="color: black">Already have an account?</span>
          </v-col>
          <v-col cols="2" style="padding-right: 0px; text-align: right;">
            <router-link to="/login" class="links">Login</router-link>

          </v-col>
        </v-row>


      </v-container>

    </div>


  </div>
    <Footer></Footer>
  </v-app>
</template>

<script>

import Footer from "@/components/home/Footer";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required, email} from 'vee-validate/dist/rules';
import LoginSubmitButtonMixin from "@/mixins/LoginSubmitButtonMixin";

extend('email', {
  ...email,
  message: 'Email must be valid',
});

// Add the required rule
extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: "RecoveryPassword",
  mixins: [LoginSubmitButtonMixin],
  components: {
    Footer,
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    email: '',
  }),
  methods: {
    restore(payload) {
      if (this.$refs.observer.validate()) {
        this.$store.dispatch('overlay/show');
        this.$store.dispatch('authentication/recoveryPassword', payload).then(
            response => {
              if (response.result) {
                this.$router.push({name: 'CheckEmail', params: {type: 'recovery_password'}});

              }

            },
            error => {
              if (error.response.status === 401) {
                this.$refs.observer.setErrors(error.response.data.validation);
              } else {
                this.$notify({
                  type: 'error',
                  group: 'leita',
                  duration: 10000,
                  title: 'An error has occurred',
                  text: 'Refresh the page and try again!'
                });
              }
            }
        )
      }

    }
  }
}
</script>

<style scoped src="@/assets/css/login.css">
</style>
